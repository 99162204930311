.root:global(.ant-picker-large) {
  display: flex;
  height: var(--input-height);
}

.root :global(.ant-picker-input) {
  padding-left: calc(var(--icon-size) + var(--gap-sm));
}

.root :global(.ant-picker-suffix),
.root :global(.ant-picker-clear) {
  margin-inline-start: initial;
  inset-inline-end: initial;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.root :global(.ant-picker-suffix) svg,
.root :global(.ant-picker-clear) svg {
  font-size: var(--icon-size);
  color: var(--color-gray-500);
}
