:global(.ant-modal-body) > .root {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  margin-top: calc(var(--container-gap) * -1);
  margin-right: calc(var(--container-gap) * -1);
  margin-left: calc(var(--container-gap) * -1);
}

.filters {
  background: var(--color-gray-100);
  padding: calc(var(--container-gap) / 2) var(--container-gap);
}

.search {
  max-width: 320px;
  margin-right: auto;
}
