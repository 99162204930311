td.table__name {
  font-weight: 500;
  color: var(--color-dark);
}

td.table__date {
  white-space: nowrap;
}

.modal__currency {
  width: auto !important;
}
