.root :global(.ant-table-thead) :global(.ant-table-cell) {
  font-size: 0.85em;
  padding-top: calc(var(--container-gap) / 2);
  padding-bottom: calc(var(--container-gap) / 2);
}

.root_clickable :global(.ant-table-tbody) :global(.ant-table-row) {
  cursor: pointer;
}

.root :global(.ant-table-tbody) :global(.ant-table-row):nth-child(even) {
  background-color: var(--color-gray-100);
}

.root :global(.ant-pagination) {
  margin: 0 !important;
  padding: calc(var(--container-gap) / 2) var(--container-gap);
}

.root :global(.ant-pagination-item) {
  font-weight: 500;
  border: none;
}

.root :global(.ant-pagination-prev) {
  margin-right: auto;
}

.root :global(.ant-pagination-next) {
  margin-left: auto;
}

.actions {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s ease 0.3s;
}

.root :global(.ant-table-tbody) :global(.ant-table-row):hover .actions {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.truncate_sm {
  max-width: 240px !important;
}

.truncate_md {
  max-width: 320px !important;
}
