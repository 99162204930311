.filter__pusher {
  margin-right: auto;
}

td.table__provider {
  font-weight: 500;
  color: var(--color-dark);
}

td.table__amount {
  white-space: nowrap;
}
