.root {
  border: 1px solid var(--color-gray-200);
  border-radius: var(--radius);
}

.header {
  padding: var(--container-gap);
}

.title {
  margin-bottom: var(--gap-xs) !important;
}

.title:last-child {
  margin-bottom: 0 !important;
}

.caption {
  color: var(--color-gray-500);
  margin-bottom: 0 !important;
}
