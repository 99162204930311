.filter__pusher {
  margin-right: auto;
}

td.table__name {
  font-weight: 500;
  color: var(--color-dark);
}

td.table__date,
td.table__rate {
  white-space: nowrap;
}
