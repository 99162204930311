.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.root_light:global(.ant-btn-primary):not(:disabled) {
  background-color: var(--color-primary-lightest);
  color: var(--color-primary);
}

.root_light:global(.ant-btn-primary.ant-btn-background-ghost):not(:disabled) {
  background-color: transparent;
  border: 2px solid var(--color-primary-lightest);
}

.root svg {
  font-size: var(--icon-size-md);
}

.root:global(.ant-btn-lg) svg {
  font-size: var(--icon-size);
}
