.root :global(.ant-modal-content) {
  padding: var(--container-gap);
}

.root :global(.ant-modal-header) {
  margin-bottom: var(--container-gap);
}

.root :global(.ant-modal-footer) {
  margin-top: var(--container-gap);
}

.title {
  margin-bottom: var(--gap-xs) !important;
}

.title:last-child {
  margin-bottom: 0 !important;
}

.caption {
  font-weight: 400;
  color: var(--color-gray-500);
  margin-bottom: 0 !important;
}

.footer__actions {
  margin-left: auto;
}
