.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 420px;
  min-height: 100vh;
  padding-top: var(--container-gap);
  padding-bottom: var(--container-gap);
}

.logo {
  display: block;
  max-width: 120px;
  margin-right: auto;
  margin-left: auto;
}

.title {
  text-align: center;
  margin-top: calc(var(--container-gap) * 2);
}

.caption {
  text-align: center;
}

.content {
  margin-top: var(--container-gap);
}
