td.table__amount {
  font-weight: 500;
  white-space: nowrap;
  color: var(--color-dark);
}

td.table__balance,
td.table__date {
  white-space: nowrap;
}
