td.table__amount {
  font-weight: 500;
  white-space: nowrap;
  color: var(--color-dark);
}

td.table__id,
td.table__date,
td.table__balance {
  white-space: nowrap;
}
