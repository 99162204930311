.root:global(.ant-picker-large) {
  display: flex;
  height: var(--input-height);
  padding-left: calc(var(--input-padding-x) + var(--icon-size) + var(--gap-sm));
}

.root :global(.ant-picker-input) {
  width: 100px;
}

.root :global(.ant-picker-input) > input {
  text-align: center;
}

.root :global(.ant-picker-suffix),
.root :global(.ant-picker-clear) {
  margin-left: var(--input-padding-x);
  inset-inline-end: initial;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.root :global(.ant-picker-suffix) svg,
.root :global(.ant-picker-clear) svg {
  font-size: var(--icon-size);
  color: var(--color-gray-500);
}
